import * as yup from "yup";

const phoneRegExp =
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

export const basicSchema = yup.object().shape({

  name: yup.string()
    .matches(/^[a-zA-Z\s]+$/, "*Name can only contain letters")
    .max(50)
    .min(3)
    .required("*Name is required"),

  email: yup
    .string()
    .email("*Plase enter valid email")
    .required("*Email is Required"),

  number: yup
    .string()
    .required("*Number is required")
    .matches(phoneRegExp, "*Number is not valid"),

  message: yup.string().required("*Message is required"),

});
