import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import LandingWrap from "./style";
import Landingab from "./Landingab";
import Lottie from "lottie-react";
import lottieAnimation1 from "../../96687-aim-hit.json";
import aboutData from "../../Pages/About/AboutData"
import Founder from "../../Component/Founder";
import Command from "../../Component/Comment";
import Footer from "../../Component/Footer";

function About() {
  return (
    <div>
      <LandingWrap id="About__us">
        <Landingab />
        <Container fluid>
          <p className="founder-content">
            Founded in 2022, foresyt is a team of innovators, planners,
            designers, and developers. Driven by a shared goal and vision, we
            work together to help companies achieve their goals through unique
            bespoke software and product solutions. Leveraging a wide-ranging
            skillset and market-leading technology, the foresyt team delivers
            high value software solutions, intuitive user interfaces, and
            remarkable end-user experiences. From the first idea to the final
            product, our team works closely alongside you to ensure an end
            result that meets every need.
          </p>
          <Row className="pad-bot">
            <Col lg={4} md={3} xs={12} xl={3} sm={3}>
              <Lottie
                align="left"
                animationData={lottieAnimation1}
                className="animate-img"
              />
            </Col>
            <Col lg={8} xl={8} md={8} xs={12} sm={8} className="mar-lef-1">
              <h1 className="mission mt-10">Our Mission & Vision</h1>
              <p className="forw-con">
                Forward-thinking and future-focused, foresyt’s vision is inspired
                by a desire to discover technology-backed solutions for an
                ecologically sustainable future. We are inspired by the
                limitless potential of technology, and excited about creating
                new products, discovering new solutions, and forging new paths
                forward into the future. Our goal is to bring this passion for
                our work to every project we work on, no matter how big or
                small.
              </p>
            </Col>
          </Row>
        </Container>
        <div className="whyus-container">
          <div className="whyus-header">
            <h1>Why us?</h1>
          </div>
          <div className="inner-container">
            {aboutData.map((item, index) => {
              return (
                <>
                  <div className="aboutus-card" key={index}>
                    <div className="aboutus-image">
                      <img src={item.image} />
                    </div>
                    <div className="aboutus-title">
                      <h2>{item.title}</h2>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
        <Command />
        <Footer />
      </LandingWrap>
    </div>
  );
}

export default About;