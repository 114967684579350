import * as yup from "yup";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const carrearSchema = yup.object().shape({
  name: yup
    .string()
    .matches(/^[a-zA-Z\s]+$/, "*Name can only contain letters")
    .required("*Name is required"),

  email: yup
    .string()
    .email("*Plase enter valid email")
    .required("*Email is Required"),

  number: yup
    .string()
    .required("*Number is required")
    .matches(phoneRegExp, "*Number is not valid"),

  message: yup.string().required("*Message is required"),

  file: yup
    .mixed()
    .required("*File is required")
    .test(
      "*fileFormat",
      "*File must be in PDF format",
      (value) => value && value.type === "application/pdf"
    ),
});
