import React, { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import { motion } from 'framer-motion'
import { useSwipeable } from "react-swipeable";

import img4 from '../../assets/bidlead1.png'
import img5 from "../../assets/bidlead3.png";
import img6 from "../../assets/bidlead2.png";

import { BsChevronRight, BsChevronLeft } from "react-icons/bs";

import { GrFormNext, GrFormPrevious } from "react-icons/gr";

function BidLead() {

  const [process2, setProcess2] = useState(1)
  const [Bitlead, setBitlead] = useState([img5, img4, img6]);
  const [isPhoneSize, setIsPhoneSize] = useState(false);
  const [isbutton, setButton] = useState(false);

  useEffect(() => {
    handleSlide2();
  }, [Bitlead]);

  function handleSlide2() {
    setInterval(() => {
      setProcess2((prev) => (prev + 1) % Bitlead.length);
    }, 6000);
  }

  useEffect(() => {
    window.addEventListener("resize", (e) => {
      if (window.innerWidth < 780) {
        setIsPhoneSize(true)
      } else {
        setIsPhoneSize(false);
      }
    })
  }, [])

  useEffect(() => {
    if (window.innerWidth < 780) {
      setIsPhoneSize(true);
    } else {
      setIsPhoneSize(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", (e) => {
      if (window.innerWidth < 780) {
        setButton(true);
      } else {
        setButton(false);
      }
    });
  }, []);

  useEffect(() => {
    if (window.innerWidth < 780) {
      setButton(true);
    } else {
      setButton(false);
    }
  }, []);

  const handleSwipe = useSwipeable({
    onSwiped: ({ dir }) => {
      console.log("dir", dir)
      if (dir === "Right") {
        if (process2 > 0) {
          setProcess2(process2 - 1);
        }
      }
      if (dir === "Left") {
        if (process2 < 2) {
          setProcess2(process2 + 1);
        }
      }
    },
  });

  const onRight = () => {
    if (process2 < Bitlead.length - 1) {
      setProcess2(process2 + 1);
    }
  };
  const onLeft = () => {
    if (process2 > 0) {
      setProcess2(process2 - 1);
    }
  };

  return (
    <>
      <section
        {...handleSwipe}
        id="section1"
        className={`${isPhoneSize ? "mobile-view" : "no"}`}
      >
        <div className="portfolio-bidlead-titles">
          <div
            className={`${isbutton ? "tymPlus-buttons" : "tymPlus-button1"}`}
          >
            <button
              onClick={onRight}
              className={`${isbutton ? "tymPlus-button button" : "no"}`}
            >
              <BsChevronLeft />
            </button>
            <button
              onClick={onLeft}
              className={`${isbutton ? "tymPlus-button button" : "no"}`}
            >
              <BsChevronRight />
            </button>
          </div>
          <h1>Auction Platform</h1>
          <p>
            Auction Platform is an adaptable bidding platform with simplicity
            and ease-of-use at the forefront of the design. This software allows
            users to place competing bids for leads, products, or services, and
            captures payment automatically at the end of each auction session.
            It also allows for custom user access filters - allowing you to
            restrict participation by location, user status, or any other
            captured metric.
          </p>
          <NavLink to="/bidlead" activeClassName="active">
            <button className="port-btn">Find Out More</button>
          </NavLink>
        </div>
        <div {...handleSwipe} className="carousel-container">
          <div className="carousel">
            {Bitlead.map((it, index) => (
              <motion.div
                key={index}
                onClick={() => {
                  setProcess2(index);
                }}
                className={`framer ${isPhoneSize ? "mobile-view" : ""}`}
                initial={{ scale: 0, rotation: -180 }}
                animate={{
                  rotate: 0,
                  scale: index === process2 ? 1 : 0.8,
                  left: isPhoneSize
                    ? `${(index - process2) * 100}%`
                    : `${(index - process2) * 65 + 15}%`,
                }}
                transition={{
                  type: "spring",
                  stiffness: 260,
                  damping: 20,
                }}
              >
                <div className="framerImg">
                  <img src={it} alt="Images" />
                </div>
              </motion.div>
            ))}
            {!isbutton && (
              <div
                className="indicator__wappper"
                style={{ position: "relative", cursor: "pointer" }}
              >
                {/* <button
                  className="slide__btn1"
                  onClick={onLeft}>
                  <GrFormPrevious />
                </button>
                <button
                  className="slide__btn"
                  onClick={onRight}
                >
                  <GrFormNext style={{ color: "red" }} />
                </button> */}
                <div className="button__indicator">
                  {Array(3)
                    .fill(0)
                    .map((item, index) => {
                      return (
                        <div
                          onClick={(e) => setProcess2(index)}
                          className={`dot ${
                            index === process2 ? "active" : ""
                          }`}
                        ></div>
                      );
                    })}
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default BidLead;
