import React from "react";

import "./index.css";

import OurValues from "../../assets/OurValues.png";

import flexible from "../../assets/Flexibility.png"

import reliability from "../../assets/Reliability.png"

import agility from "../../assets/Agility.png";

import sustainability from "../../assets/Sustainability.png";

function Values() {
  return (
    <>
      <div className="values-container">
        <div className="values-header">
          <h1>Our Values</h1>
          <p>
            At foresyt our values are at the core of everything we do, and drive
            every project we undertake.
          </p>
        </div>
        <div class="valuesSection">
          <div class="cell">
            <img className="flexibleImg" src={sustainability} />
            <div>
              <h2>Sustainability</h2>
              <p style={{ color: "#535674" }}>
                We understand that sustainability is the key to the future, no
                matter what industry you’re in. foresyt puts sustainability at
                the heart of all of our projects, creating products that support
                a greener planet.
              </p>
            </div>
          </div>
          <div class="cell cell-image">
            <img src={OurValues} alt="Placeholder Image" />
          </div>
          <div class="cell">
            <img className="flexibleImg" src={flexible} />
            <div>
              <h2>Flexibility</h2>
              <p style={{ color: "#535674" }}>
                {" "}
                The software you use should be tailored to your business
                practices, not the other way around. We focus on customisation
                and adaptability throughout our products, creating solutions
                that meet your needs - whatever they may be.
              </p>
            </div>
          </div>
          <div class="cell">
            <img className="flexibleImg" src={agility} />
            <div>
              <h2>Agility</h2>
              <p style={{ color: "#535674" }}>
                {" "}
                Constant innovation means that the tech landscape is always
                changing. Our team is equipped with the skills, knowledge, and
                support to not only understand new technologies, but to leverage
                their full potential.
              </p>
            </div>
          </div>
          <div class="cell">
            <img className="flexibleImg" src={reliability} />
            <div>
              <h2>Reliability</h2>
              <p style={{ color: "#535674" }}>
                We know that to be successful your business needs tools it can
                rely on. foresyt products are built to be what you need when you
                need it.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Values