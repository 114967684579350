import penIcon from "../../assets/penImg.svg";
import mobileIcon from "../../assets/mobileImg.svg";
import dataIcon from "../../assets/dataImg.svg";
import cloudIcon from "../../assets/cloudImg.svg";
import workflow1 from "../../assets/workflow1.png"
import workflow2 from "../../assets/workflow2.png"
import workflow3 from "../../assets/workflow3.png"


export const data = [
  {
    id: 1,
    img: penIcon,
    title: "UI & UX Design",
    content:
      "We provide a complete user experience and user interface service for your brand.",
  },
  {
    id: 2,
    img: mobileIcon,
    title: "AWS Services",
    content:
      "We offer reliable, scalable, and inexpensive cloud computing services using AWS.",
  },
  {
    id: 3,
    img: dataIcon,
    title: "Application Development",
    content:
      "We create apps tailored to meet the needs of your business and your users.",
  },
  {
    id: 4,
    img: cloudIcon,
    title: "Cloud Computing",
    content:
      "We provide custom cloud applications, resources, and data storage via our cloud services.",
  },
];

export const workflow = [
  {
    id: 1,
    img: workflow1,
    title: "Research",
    content:
      "From the very first idea, foresyt helps you to craft products that are relevant, meaningful, and impactful. To stand out in the market you must first understand it. Our team draws on decades worth of experience to create products that stand out from the start.",
  },
  {
    id: 2,
    img: workflow2,
    title: "Design",
    content:
      "Well executed design is a cornerstone of any successful digital product. Our team of designers leverage their experience and training to create unique, intuitive digital experiences that are guaranteed to exceed your expectations.",
  },
  {
    id: 3,
    img: workflow3,
    title: "Development",
    content:
      "Each product created by foresyt’s skilled development team is tailored to meet the unique needs of your business. Our developers bring a wide range of skills and experience to your projects, working closely with your business to ensure an end result that you are happy with."
  },
];

