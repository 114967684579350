import React from "react";
import "../Founder/index.css";
import founderImg from '../../assets/founderImg.png'

function Founder() {
  return (
    <>
      <div className="Co-founder">
        <h1 style={{ color: "#1A02B1", fontWeight: "bold" }}>
          About our Founder
        </h1>
        <div className="founder-container alignments">
          <div className="founder-image">
            <img src={founderImg} />
          </div>
          <div className="founder">
            <h1>Neeru Maini</h1>
            <p>
              Throughout my career, I have always been exploring better ways to
              make a difference, better ways to help people around me, better
              ways to empower others to achieve success, better ways to share my
              learnings and better ways to make a positive impact. Technology
              has transformed the way we think, do business, do work, do
              shopping, make friends and communicate. Technology has not only
              disrupted many industries but has also driven enormous efficiency
              bringing the power of choice to the end user. Technology has not
              only revoutionalized the way we do business today, it has also
              helped in making our environment sustainable, something which
              companies are continuously seeking to improve. I am passionate
              about technology and sustainability. We all need to be mindful of
              the difference we can make to our planet.<br></br> With many years
              of experience across the technology sector and related industries,
              I not only bring knowledge, but a passion for innovation and
              sustainability that drives the vision that is foresyt. foresyt’s
              foundation is based on the four pillars of Sustainability,
              Agility, Flexibility and Reliability. Having spent my formative
              childhood years in Africa, I feel a strong connection with the
              continent. I wish to bring all the experience I have gained
              working in Australia to Africa. foresyt is the embodiment of my
              goal. I endeaveour to use the limitless potential for connection
              and growth that technology brings, with the aim of creating
              positive lasting impacts on Africa and beyond.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Founder;