import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../../assets/foresyt.svg";
import "./index.css";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer__nav">
        <div className="navigator">
          <NavLink exact className="nav-link">
            <img className="foresyt-logo" src={logo} alt="foresyt" />
          </NavLink>
          <div className={`nav_items foot_items`}>
            <NavLink
              to="/"
              activeClassName="active"
              className="nav-link"
            >
              Home
            </NavLink>
            <NavLink
              to="/about"
              activeClassName="active"
              className="nav-link"
            >
              About Us
            </NavLink>
            <NavLink
              to="/tymplus"
            >
              Portfolio
            </NavLink>
            <NavLink
              to="/career"
              activeClassName="active"
              className="nav-link"
            >
              Careers
            </NavLink>
            <NavLink
              to="/pricing"
              activeClassName="active"
              className="nav-link"
            >
              Pricing
            </NavLink>
          </div>
        </div>
      </div>
      <div className="footer-secton">
        <div className="footer-mailId">
          <p>Write to us at</p>
          <p>info@foresyt.co</p>
        </div>
        <div className="footer-number">
          <p>Talk to us</p>
          <p>(02) 8630 9850</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;