import React, { useEffect, useState } from "react";

import { NavLink } from "react-router-dom";

import { motion } from "framer-motion";
import { useSwipeable } from "react-swipeable";

import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";

import img1 from "../../assets/tymplus1.png";
import img2 from "../../assets/tymplus2.png";
import img3 from "../../assets/tymplus3.png";
import zIndex from "@mui/material/styles/zIndex";

function TymeplusSection() {
  const [process, setProcess] = useState(0);
  const [item, setItem] = useState([img1, img2, img3]);
  const [isPhoneSize, setIsPhoneSize] = useState(false);
  const [isbutton, setButton] = useState(false)

  useEffect(() => {
    window.addEventListener("resize", (e) => {
      if (window.innerWidth < 780) {
        setIsPhoneSize(true);
      } else {
        setIsPhoneSize(false);
      }
    });
  }, []);

  useEffect(() => {
    if (window.innerWidth < 780) {
      setIsPhoneSize(true);
    } else {
      setIsPhoneSize(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", (e) => {
      if (window.innerWidth < 780) {
        setButton(true);
      } else {
        setButton(false);
      }
    });
  }, []);

  useEffect(() => {
    if (window.innerWidth < 780) {
      setButton(true);
    } else {
      setButton(false);
    }
  }, [])

  useEffect(() => {
    handleSlide();
  }, [item]);

  function handleSlide() {
    setInterval(() => {
      setProcess((prev) => (prev + 1) % item.length);
    }, 6000)
  }

  const handleSwipe = useSwipeable({
    onSwiped: ({ dir }) => {
      console.log("dir", dir);
      if (dir === "Right") {
        if (process > 0) {
          setProcess(process - 1);
        }
      }
      if (dir === "Left") {
        if (process < 2) {
          setProcess(process + 1);
        }
      }
    },
  });

  const onRight = () => {
    if (process < item.length - 1) {
      setProcess(process + 1);
    }
  };
  const onLeft = () => {
    if (process > 0) {
      setProcess(process - 1);
    }
  };

  return (
    <>
      <section
        {...handleSwipe}
        className={`${isPhoneSize ? "mobile-view" : "no"}`}
      >
        <div
          className="portfolio-heading"
          style={{ height: "100%", width: "100%" }}
        >
          {/* <h1>Portfolio</h1> */}
          <div className="carousel-container">
            <div className="carousel-tymplus">
              {item.map((it, index) => (
                <motion.div
                  key={index}
                  onClick={() => {
                    setProcess(index);
                  }}
                  className={`framer ${isPhoneSize ? "mobile-view" : ""}`}
                  initial={{ scale: 0, rotation: -180 }}
                  animate={{
                    rotate: 0,
                    scale: index === process ? 1 : 0.8,
                    left: isPhoneSize
                      ? `${(index - process) * 100}%`
                      : `${(index - process) * 65 + 15}%`,
                  }}
                  transition={{
                    type: "spring",
                    stiffness: 260,
                    damping: 20,
                  }}
                >
                  <div className="framerImg">
                    <img src={it} alt="Images" />
                  </div>
                </motion.div>
              ))}
              {!isbutton && (
                <div
                  className="indicator__wappper-tymplus"
                  style={{ position: "relative", cursor: "pointer" }}
                >
                  {/* <button
                    className="slide__btn1"
                    onClick={onLeft}
                  >
                    <GrFormPrevious />
                  </button>
                  <button
                    className="slide__btn"
                    onClick={onRight}
                  >
                    <GrFormNext style={{ color: "red" }} />
                  </button> */}
                  <div className="button__indicator">
                    {Array(3)
                      .fill(0)
                      .map((item, index) => {
                        return (
                          <div
                            onClick={(e) => setProcess(index)}
                            className={`dot ${
                              index === process ? "active" : ""
                            }`}
                          ></div>
                        );
                      })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className={`${isbutton ? "tymPlus-button" : "tymPlus-button1"}`}>
          <button
            onClick={onRight}
            className={`${isbutton ? "tymPlus-button button" : "no"}`}
          >
            <BsChevronLeft />
          </button>
          <button
            onClick={onLeft}
            className={`${isbutton ? "tymPlus-button button" : "no"}`}
          >
            <BsChevronRight />
          </button>
        </div>
        <div className="portfolio-titles-tymplus">
          <h1>Human Resource Management Information System - HRMIS</h1>
          <p>
            Our Human Resource Management Information System software is an in-depth
            interdepartmental HR and administrative tool designed to help
            businesses keep track of staff entitlements, hours worked, and other
            customisable metrics. Features such as location validation and
            custom overview reporting have made our Human Resource Management Information System
            platform a success for businesses with a large number of employees,
            or staff spread across multiple locations.
          </p>
          <div className="portfolio-btn">
            <NavLink to="/tymplus" activeClassName="active">
              <button className="port-btn">Find Out More</button>
            </NavLink>
          </div>
        </div>
      </section>
    </>
  );
}
export default TymeplusSection;
