import React, { useState } from "react";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import bidleadImg1 from "../../assets/bidlead2.png";
import bidleadImg2 from "../../assets/bidlead3.png";
import bidleadImg3 from "../../assets/bidlead1.png";
import bidleadImg4 from "../../assets/bidlead4.png";
import bidleadImg5 from "../../assets/bidlead5.png";
import Projects from "../Projects";
import Navbar from "../../Component/Navbar/Navbar";
import Command from "../../Component/Comment";
import Footer from "../../Component/Footer";

function Bidleadpage() {
  const [Trekr, setTrekr] = useState([
    bidleadImg2,
    bidleadImg1,
    bidleadImg4,
    bidleadImg5,
  ]);
  const [image, isImage] = useState(bidleadImg3);
  const onRight = () => {
    let temp = Trekr.shift();
    Trekr.push(image);
    isImage(temp);
  };

  const onLeft = () => {
    let temp = Trekr.pop();
    Trekr.unshift(image);
    isImage(temp);
  };

  function clickToChange(it, idx) {
    let trkr = Trekr;
    delete trkr[idx];
    trkr[idx] = image;
    setTrekr(trkr);
    isImage(it);
  }

  return (
    <>
      <Navbar isShow={true} />
      <div className="Portfoliopage-Image" id="portfolio">
        <div className="Portfolio-logo">{/* <h1>Portfolio</h1> */}</div>
      </div>
      <div className="tymePage-container">
        <div className="tymePage-header">
          <h1>Auction Platform</h1>
          <p style={{ textAlign: "justify" }}>
            The foresyt auction platform is an adaptable bidding platform with
            simplicity and ease-of-use at the forefront of the design. The
            auction platform allows users to place competing bids for leads,
            products, or services, and captures payment automatically at the
            end of each auction session. The system allows for custom user
            access filters - allowing you to restrict participation by
            location, user status, or any other captured metric.
          </p>
        </div>
        <div className="portal-section">
          <div className="tymePlus-carousel">
            <div className="tymplus-carousel-motion">
              <img src={image} alt="carousel-1" />
            </div>
            <div className="tymplus-carousel-motion1">
              {Trekr.map((item, index) => {
                return (
                  <img
                    alt="carouselimg"
                    style={{ cursor: "pointer" }}
                    src={item}
                    key={index}
                    onClick={() => clickToChange(item, index)}
                  />
                );
              })}
            </div>
          </div>
          <div className="portal-button">
            <button onClick={onRight}>
              <BsChevronLeft />
            </button>
            <button onClick={onLeft}>
              <BsChevronRight />
            </button>
          </div>
        </div>
        <div className="tymeplus-All-contents">
          <p style={{ textAlign: "justify" }}>
            The auction platform is also supported by a mobile app, so bidders
            can access the platform from anywhere. The platform can be adapted
            to run auctions for almost any product or service, and custom APIs
            allow it to integrate seamlessly with your existing systems.
          </p>
          <p style={{ textAlign: "justify" }}>
            Users are notified when a new auction they are eligible for
            begins, and can see auction results and history from their
            personal dashboard at any time.
          </p>
        </div>
      </div>
      <Projects />
      <Command />
      <Footer />
    </>
  );
}

export default Bidleadpage;