import React from "react";
import "../Contactus/index.css"
import Footer from "../Footer";
import Navbar from "../Navbar/Navbar";
import CommendSection from "./CommendSection";
import ContactSction from "./ContactSection";
import { LazyLoadImage } from "react-lazy-load-image-component";
import contactImg from "../../assets/contactImgbg.png";

function Contact({ contactRef, contact__activeRef }) {
  return (
    <>
      <div ref={contact__activeRef}>
        <Navbar isShow={true} />
        <div className="contact-container-section" ref={contactRef}>
          {/* <LazyLoadImage src={contactImg} /> */}
          <ContactSction />
        </div>
        <CommendSection />
        <Footer />
      </div>
    </>
  );
}
export default Contact