
import projectImg1 from "../../assets/tymplus3.png";
import projectImg2 from "../../assets/trekr3.png";
import projectImg3 from "../../assets/bidlead1.png"
import projectImg4 from "../../assets/mystro.png"
const ProjectData = [
  {
    id: 1,
    image: projectImg1,
    header: "Human Resource Management Information System - HRMIS",
    url: "/tymplus",
    contents:
      "Human Resource Management Information System is an in-depth interdepartmental HR and administrative tool designed to help businesses keep…",
  },
  {
    id: 2,
    image: projectImg2,
    header: "Project Management Software",
    url: "/trekr",
    contents:
      "Our project management software allows you to create tailored processes and workflows that match your business needs. Manage multiple projects and departments across your entire organisation.",
  },
  {
    id: 3,
    image: projectImg3,
    header: "Auction Platform",
    url: "/bidlead",
    contents:
      "The foresyt auction platform is a customisable bidding software with simplicity and ease-of-use at the forefront of the design. This platform allows users to place competing bids for leads, products, or services, getting you the best price for your products.",
  },
  {
    id: 4,
    image: projectImg4,
    header: "Workflow and Form Builder",
    url: "/Workflow_and_FormBuilder",
    contents:
      "The foresyt workflow and form builder is an automation powerhouse for lead generation, data collection, workflow management, and more. ",
  },
];
export default ProjectData;