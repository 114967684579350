import React, { useRef, useState } from "react";
import { useFormik } from "formik";
import { basicSchema } from "../schemas";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { Modal, Spinner } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";

const normalizeNumber = (value) => {
  // console.log('normalizeNumber  value', value.length)
  const firstIndex = value.length === 1
  const lastIndex = value.length - 1

  if (value.length <= 14) {   //* Eg. +913431533234 some country have 2 digit country code some country have 3 digit.
    if (value == "+" && firstIndex) {
      return value
    } else if (value[lastIndex] == "+") {
      return value.substring(0, lastIndex)
    }
    else {
      return value.replace(/[^0-9+]/g, '')
    }
  }
  return value.substring(0, 14)
}

// import ReCAPTCHA from "react-google-recaptcha";
function ContactSction() {

  const [showModal, setShowModal] = useState(false);
  const [recaptcha, setRecaptcha] = useState(false);
  const [loading, setLoading] = useState(false)

  const onSubmit = (values, actions) => {
    recaptcha && setLoading(true)
    recaptcha && fetch(
      "https://d4n4e2rgq8.execute-api.ap-south-1.amazonaws.com/test/contactus",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        actions.setSubmitting(false);
        setLoading(false)
        setShowModal(true);
        contact__reset.current.reset();
      })
      .catch((error) => {
        console.error(error);
        actions.setSubmitting(false);
        setLoading(false)
      });
  };

  const contact__reset = useRef(null)

  const { values, errors, touched, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      name: "",
      email: "",
      number: "",
      message: "",
    },
    validationSchema: basicSchema,
    onSubmit,
  })

  const reCaptchaOnChange = (value) => {
    setRecaptcha(true)
  }

  return (
    <>
      <div className="contact-container">
        <div className="contact-heading">
          <h1>Contact us</h1>
          <span style={{ color: "#1A02B1", fontWeight: "500" }}>
            Ready to learn more about how foresyt can help your business grow?
            Send us a message and our team will be in touch.
          </span>
        </div>
        <form onSubmit={handleSubmit} name="contact__form" ref={contact__reset}>
          <div className="contact-input">
            <FloatingLabel
              controlId="floatingName"
              label="Name"
              className="mb-3 float__tag"
              style={{ color: "white", marginBottom: "0rem!important" }}
            >
              <Form.Control
                className="contact-input-style shadow-none"
                onChange={handleChange}
                type="name"
                name="name"
                // id="name"
                placeholder="Enter the name"
              />
            </FloatingLabel>
            {errors.name && touched.name && (
              <p className="text-danger">{errors.name}</p>
            )}
            <FloatingLabel
              controlId="floatingPassword"
              label="Email address"
              className="mb-3 float__tag"
              style={{ color: "white" }}
            >
              <Form.Control
                className="contact-input-style shadow-none"
                onChange={handleChange}
                type="email"
                // id="email"
                name="email"
                placeholder="Enter the email"
              />
            </FloatingLabel>
            {errors.email && touched.email && (
              <p className="text-danger">{errors.email}</p>
            )}
            <FloatingLabel
              controlId="floatingInput"
              className="mb-3 float__tag"
              label="Contact number (including country code)"
              style={{ color: "white" }}
            >
              <Form.Control
                className="contact-input-style shadow-none"
                onChange={(e) => {
                  const { value } = e.target
                  setFieldValue("number", normalizeNumber(value), false)
                  e.target.value = normalizeNumber(value)
                }}
                type="text"
                // id="number"
                name="number"
                placeholder="Enter the number"
              />
            </FloatingLabel>
            {errors.number && touched.number && (
              <p className="text-danger">{errors.number}</p>
            )}
            <label style={{ color: "white", marginTop: "1rem" }}>
              Type your Message:
            </label>
            <br></br>
            <textarea
              type="text"
              className="contact-text-area"
              style={{ marginTop: "10px" }}
              onChange={handleChange}
              id="message"
            ></textarea>
            {errors.message && touched.message && (
              <p className="text-danger">{errors.message}</p>
            )}

            <div className="mt-2">
              <ReCAPTCHA
                sitekey="6Ld_6Z0kAAAAAIzctrEhT2cPhmtpVE2GRqiQFH5c" // Create site Key from https://www.google.com/recaptcha/admin/create
                onChange={reCaptchaOnChange}
              />
            </div>

            {!recaptcha && touched.email && (
              <p className="text-danger">{!recaptcha && "*reCAPTCHA is required"}</p>
            )}

          </div>



          <div className="submit-section">
            <button type="submit">{loading ? <Spinner animation="border" size="sm" /> : "SUBMIT"}</button>
          </div>
        </form>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton closeVariant="white" style={{ background: "linear-gradient(90deg, rgba(253,139,0,1) 7%, rgba(26,2,177,1) 100%, rgba(253,139,0,1) 100%)" }}>
          <Modal.Title style={{ color: "#1A02B1", fontSize: "32px" }}>
            foresyt.co
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ color: "#FD8B00", fontSize: "22px", paddingBottom: ".5rem" }}>
            Thank you for contacting us.<br />
            The foresyt team will be in touch with you shortly.
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default ContactSction;