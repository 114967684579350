import Styled from "styled-components";
import mask from "../../assets/maskimg.png";
import bulb from "../../assets/lightbulb.png";
import whyus from "../../assets/whyUs.svg"

const LandingWrap = Styled.div`{
    .active__true {
  color: blue !important;
  background-color: #fd8b00;
}
    .con-page{
        background:transparent url(${mask}) 0% 0% no-repeat padding-box;
        height: 50vh;
        background-size: cover;
        background-position: bottom;  
        position:relative;
        padding: 0px;
    }
    .whyus-container{
         background:transparent url(${whyus}) 0% 0% no-repeat padding-box;
         background-size: 100% 100%;
        /* background: transparent linear-gradient(110deg, #140152 0%, #22018E 100%) 0% 0% no-repeat padding-box; */
        padding: 4rem 5rem;
        background-size: cover;
        margin-top:-80px;
        background-position: bottom;  
    }
    .com{
        margin-left:-45px;
        display: flex;
        align-items: center;
        gap: 5rem;
    }
    .about-con{
        display: flex;
        justify-content: start;
        align-items: end;
        width: 100%;
        position:absolute;
        bottom:2%;
    }
    .container1{
        height: 100%;
        width:100vw ;
        background:transparent url(${mask}) 0% 0% no-repeat padding-box;    
        background-position:top;
        background-size:cover ;
        opacity: 1;
    }
    .whyus-header h1{
        font-size: 36px;
        padding-bottom: 1rem;
        font-weight: bold;
        color: #fff;
    }
    .inner-container{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
        gap: 2rem;
    }
    .aboutus-card{
        width: 230px;
        height: 280px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 3rem;
        justify-content: center;
        align-items: center;
        text-align: center;
        background: transparent;
        border: 2px solid #FD8B00;
        padding-bottom: 1rem;
        border-radius: 20px;
    }
    .aboutus-image img{
        width: 100%;
        height: 100px;
    }
    .aboutus-title h2{
        font-size: 20px;
        color: #fff;
        font-weight:bold;
    }
   
    .head-title{
        color:#D155FF;
    }
    .head-con{
        color:white;
        font-weight:bold;
        padding:25px;
        font-size:40px;
        margin-left:12%;
    }
    .page-align{
        display:flex;
        padding:50px;
        padding-bottom:10%;
    }
    .par-letr{
        font-weight:600;
        font-size:17px;
        color:white;
    }
    .btn-con{
        border: 2px solid #D155FF;
        background:bottom;
    }
    .about-con{
        font-size:140px;
        color:white;
        padding:50px;
        opacity:0.85;
        padding-left:80px;
        line-height:0px;
        font-weight:bold;
        /* color: #1A02B1; */
    }
    .founder-content{
        padding:30px 45px 30px 70px;
        font: normal normal normal 17px Jost;
        margin-bottom: -200px;
        opacity:0.85;
        color:#535674;
    }
    .mission{
        color:#1A02B1;
        padding-top:372px;
        font-weight:bold;
    }
    .forw-con{
        color:#535674;
        font: normal normal normal 17px Jost;
    }
    .light-img{
        margin:15%;
        width:auto;
    }
    .light-img1{
        margin:21%;
        width:auto;
    }
    .crd-con{
        border:2px solid #FD8B00;
        border-radius: 20px;
        width:270px;
        height:330px;
        margin-right:30px;
        background:bottom;
    }
    .page-align1{
        display:flex;
        justify-content: space-evenly;
        padding-bottom:4%;
        height:450px;
    }
    .page-align2{
        display:flex;
        justify-content: space-evenly;
        margin-top:-150px;
        padding-bottom:4%;
        height:500px;
    }
    .bulb-img{
        background: url(${bulb});
        }
    .alin-cen{
        text-align:center;
        color:#EF8F35;
        font-size: 35px;
        font-weight:bold;
        margin-top:50px;
        margin-bottom:0px;
    }
    .box-di{
        border: 2px solid #EF8F35;
        border-radius: 15px;
        background:#fdf4f6 0% 0% no-repeat padding-box;
        opacity: 1;
        width:90%;
        height:50%; 
    }
    .pad1{
        padding-bottom:10%;
        margin-top:35px;

    }
    .p-dec{
        color:#535674;
        font-size:13px;
        padding-left:20px;
        padding-bottom: 18px;
        margin:0px;
    } 
    .p-dec1{ 
        color:#535674;
        font-size:27px;
        padding-left:20px;
        margin:0px;
    }
   .animate-img{
        width:800px;
        margin-left: -190px;
   }
   .box-cnt{
        color:white;
        font-size:22px;
        font-weight:bold;
        text-align:center;
   }
   .girl-img{
        width:40px;
   }
   .arrow-img{
        width:40px;
        margin-right:5%;
   }
   .animate-img2{  
        padding: 20px 20px 20px 0px;
    }
    br.responsive {
        display: inline; 
    }

    @media screen and (max-width: 320px) {
        .page-align2{
            height:600px;
        }
        .alin-cen {
            font-size: 18px;
        }
        .page-align1  {
            height:200px;
        }
        .com{
            display:flex;
            margin-left:0px;
            justify-content:center;
        }
        .pad1{
            margin-left: 16px;
        }
        .arrow-img {
            width: 36px;
        }
        .p-dec1{
            font-size:18px;
        }
        .crd-con {
            width: 137px;
            height: 210px;
            margin-top:11px
         }
        .mission{
            padding-top:0px;
            margin-top: -134px;
            text-align: center;
        }
        .box-cnt {
            font-size: 14px;
        }
        .about-con {
            font-size: 70px;
            padding:14px;
        }
        .animate-img {
            width: 543px;
            margin-left: -120px;
        }
        .founder-content {
            font-size: 18px;
            padding: 30px 15px 50px 15px;
        }
        .forw-con {
            padding: 15px 15px 80px 15px;
        }
        .con-page1 h1 {
            margin-left: 0px;
        }
        .whyus-header h1{
            font-size: 36px;
            margin-left: -2rem;
        }
    }
    @media (min-width: 321px) and (max-width:408px) { 
        .page-align1{
            height:200px;
        }
        .about-con {
            font-size: 70px;
            padding:20px;
        }
        .com{
            margin-left:0px;
            display:flex;
            justify-content:center;
        }
        .founder-content {
            padding: 30px 15px 50px 15px;
            font-size: 18px;
        }
        .animate-img {
            width: 543px;
            margin-left: -90px;
            margin-top:25px;
        }
        .mission{
            padding-top:0px;
            margin-top: -134px;
            text-align: center;
        }
        .con-page1 h1{
            margin-left: 0px
        }
        .forw-con {
            padding: 15px 15px 80px 15px;
        }
        .crd-con {
            width: 147px;
            height: 217px;
            margin-top:27px
        }
        .box-cnt {
            font-size: 16px;
        }
        .page-align2{
            height:620px;
        }
        .alin-cen {
            font-size: 18px;
        }
        .arrow-img {
            width: 36px;
        }
        .p-dec1{
            font-size:18px;
        }
        .pad1{
            margin-left: 16px;
        }
        .whyus-header h1{
            font-size: 36px;
            margin-left: -2rem;
        }
    }
    @media (min-width:408px) and (max-width:450px) { 
        .page-align1{
            height:200px;
        }
        .about-con {
            font-size: 86px;
            margin-left:10px;
            padding:25px;
        }
        .com{
            margin-left:0px;
            display:flex;
            justify-content:center;
        }
        .founder-content {
            padding: 30px 20px 30px 20px;
            font-size: 18px;
        }
        .animate-img {
            width: 600px;
            margin-left: -100px;
            margin-top:25px;
        }
        .mission{
            padding-top:0px;
            margin-top: -134px;
            text-align: center;
        }
        .con-page1 h1{
            margin-left: 20px
        }
        .forw-con {
            padding: 15px 20px 80px 20px;            
        }
        .crd-con {
            width: 90%;
            height: 250px;
            margin-top:27px;
            margin-left:10px;
        }
        .box-cnt {
            font-size: 17px;
        }
        .page-align2{
            height:700px;
        }
        .whyus-header h1{
            font-size: 36px;
            margin-left: -2rem;
        }
    }

    @media (min-width:769px) and (max-width:1226px) { 
        br.responsive {
            display: none;
        }
            .animate-img {
                width: 578px;
                margin-left: -176px;
                margin-top: 90px;
                padding: 20px;
        }
        .forw-con {
            font-size: 15px;
        }
        .mission {
            padding-top: 332px;
        }
        .founder-content {
            padding: 30px 15px 30px 50px;
            margin-bottom: -222px;
            font-size: 13px;
        }
        .crd-con {
            width: 100%;
            height: 90%;
        }
        .box-cnt {
            font-size: 17px;
        }
        .page-align1 {
            height:350px;
        }
        .nav-items{
            gap: 50px;
        }
        .con-page1 h1{
            margin-left: 158px;
        }
    }
    
    @media (min-width:450px) and (max-width:577px) { 
        .about-con {
            font-size:86px;
            padding:35px;
        } 
        .founder-content {
            padding:15px;
            font-size: 19px;
        }
        .mission{
            padding-top:0px;
            margin-top: -134px;
            text-align: center;
        }
        .forw-con {
            padding-bottom: 140px;
            padding-left:15px;
        }
        .animate-img {
            width: 643px;
            margin-left: -89px;
        }
        .alin-cen {
            font-size: 25px;
            margin-top:33px;
        }
        .crd-con {
            width: 88%;
            height: 92%;
        }
        .box-cnt {
            font-size: 14px;
        }
        .arrow-img {
            width: 36px;
          }
          .page-align2{
            height:700px;
          }
    }
    @media (min-width:577px) and (max-width:769px) { 
        .about-con {
            font-size: 120px;
            padding: 35px;
            padding-left: 50px;
        }
        .page-align1 {
            height:350px;
        }
        .founder-content {
            padding: 30px 40px 0px 40px;
            font-size: 14px;
        }
        .animate-img {
            width: 578px;
            margin-left: -191px;
            margin-top: 67px;
            padding: 60px;
        }
        .forw-con {
          font-size:14px;
        }
        .mission{
            padding-top:259px;
        }
       .mar-lef-1{
            margin-left: 43px;
        }
        .crd-con {
            width: 100%;
            height: 75%;
        }
        .box-cnt{
            font-size: 16px;
        }
        .con-page1 h1{
            margin-left: 0px;
        }
    }
    @media (min-width:1228px) and (max-width:2560px){
        .con-page1 h1 {
            margin-left: 0px;
        }
        .con-page1{
            margin-top:-150px;
    }
    }
}
`;
export default LandingWrap;