import React from "react";

import "./index.css"

import { useLocation } from "react-router";

import ProjectData from "./ProjectData"

import { useNavigate } from "react-router-dom";

function Projects() {

  const navigate = useNavigate();
  const location = useLocation();
  let pathname = location.pathname;

  return (
    <>
      <div className="Project-container">
        <div className="project-header">
            <h1>More Projects</h1>
        </div>
        <div className="all-projects" >
          {ProjectData.map((item, index) => {
            return (
              <>
                {item.url !== pathname ? (
                  <div key={index}      
                    onClick={() => navigate(item.url)}
                    className="project-card"
                    style={{ cursor: "pointer" }}
                  >
                    <div className="project-image" >
                      <img src={item.image} alt="project"/>
                    </div>
                    <div className="project-content-header">
                      <h2>{item.header}</h2>
                      <p>{item.contents}</p>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </>
            );
          })}           
        </div>
      </div>
    </>
  );
}
export default Projects