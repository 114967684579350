
import img1 from "../../assets/lightbulb.png";
import img2 from "../../assets/computerIcon.png"
import img3 from "../../assets/radioIcon.png"
import img4 from "../../assets/buzzleIcon.png"
const aboutData = [
    {
        image: img1,
        title: "End-to-end bespoke solutions",
    },
    {
        image: img2,
        title: "Cross-platform development services",
    },
    {
        image: img3,
        title: "Collaborative communication and work style",
    },
    {
        image: img4,
        title: "Efficient, experienced team"
    }
]
export default aboutData;