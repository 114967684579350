import React from "react";

import Values from "../Values";

import "./index.css";

import { data, workflow } from './ServiceContant'

function Services() {
  return (
    <>
      <div>
        <div className="service-container">
          <div className="service-contents">
            <h1>Services</h1>
            <p>
              We provide a range of design and development services, combined
              to create the perfect solution for your business at a
              competitive price.
            </p>
          </div>
          <div className="cardService">
            {data.map((item, index) => {
              return (
                <>
                  <div className="card-contents" key={index}>
                    <div className="card-image">
                      <div className="imgage">
                        <img src={item.img} alt="service-images" />
                      </div>
                    </div>
                    <div className="service-content">
                      <h2>{item.title}</h2>
                      <p>{item.content}</p>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
        <Values />
        <div className="workflow">
          <div className="workflow-title">
            <h2>What we do</h2>
            <p>
              We are a full-stack design and development studio with a focus
              on sustainability-driven digital solutions. Using leading tools,
              technologies and approaches, we create tailored, scalable,
              end-to-end solutions for your business.
            </p>
          </div>
          <div className="worlflow-all-cards">
            {workflow.map((item, index) => {
              return (
                <>
                  <div className="workflow-cards" key={index}>
                    <div className="flow-image">
                      <img src={item.img} alt="workflow-images" />
                    </div>
                    <div className="workflow-content">
                      <h2>{item.title}</h2>
                      <p>{item.content}</p>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default Services;