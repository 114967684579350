import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../Component/Navbar/Navbar";
import Services from "../../Component/Services/index";
import Footer from "../../Component/Footer/index";
import Command from "../Comment/index";
import Founder from "../Founder";
import Portfolio from "../Portfolio";
import img1 from "../../assets/Group4398.jpg"
import img2 from "../../assets/Group4399.jpg"
import img3 from "../../assets/Group4400.jpg"
import img4 from "../../assets/Group4401.jpg"
import { Spinner } from "react-bootstrap";
import "./index.css";

export const IMAGES = [
  {
    id: 0,
    url: img1
  },
  {
    id: 1,
    url: img2
  },
  {
    id: 2,
    url: img3
  },
  {
    id: 3,
    url: img4
  },

]

function Landing() {
  const portfolioRef = useRef(null);
  const [currentImage, setCurrentImage] = useState(0);

  const [imgsLoaded, setImgsLoaded] = useState(false)

  useEffect(() => {
    const loadImage = image => {
      return new Promise((resolve, reject) => {
        const loadImg = new Image()
        loadImg.src = image.url
        loadImg.className = "page-container-img"
        loadImg.onload = () => resolve(image.url)
        loadImg.onerror = err => reject(err)
      })
    }

    Promise.all(IMAGES.map(image => loadImage(image)))
      .then(() => setImgsLoaded(true))
      .catch(err => console.log("Failed to load images", err))
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((currentImage) => {
        if (currentImage === 3) return 0
        return (currentImage + 1)
      });
    }, 4000);
    return () => clearInterval(interval);
  }, []);

  function portfolioClick() {
    portfolioRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }

  return (
    <div
      className="home__header"
    >

      {imgsLoaded ? (
        IMAGES.map(image => (
          <img className="page-container-img" style={{ visibility: image.id === currentImage ? "visible" : "hidden" }} key={image.id} src={image.url} alt="Human" />
        ))
      ) : (
        <div className="page-container-img"><Spinner animation="border" size="sm" /></div>
      )}

      <div className="for-both" id="home">
        <Navbar isShow={true} />
        <div
          className="page-container"
        >
          <div className="page-contents">
            <div>
              <p className="p1">Welcome to foresyt</p>
              <p className="p2">
                Creating forward-thinking, sustainability-focused digital
                solutions
              </p>
              <p className="p3">
                Helping brands and businesses grow through innovative software
                solutions.
              </p>
              <div className="join-btn">
                <h2>Join us now!</h2>
                <button onClick={portfolioClick}>View Our Work</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Services />
      <Portfolio ref={portfolioRef} />
      <Founder />
      <Command />
      <Footer />
    </div>
  );
}

export default Landing;