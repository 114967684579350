import Styled from "styled-components";
import back from "../../assets/career-back.png"


const Careerwrap = Styled.div`{
    .careear_image{
        width: 100%;
        height: 100px;
        /* background-color: red; */
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #555876;
        border-radius: 10px;
        margin-bottom: 12px;
    }
    .Carrer-head{
        background:transparent url(${back}) 0% 0% no-repeat padding-box;
        width:100vw;
        height: 800px;  
        background-size: cover;
        background-position: bottom;  
    }
    .career-page{
        height:700px;
        padding-top:100px;
    }
    .errors{
        color: #1A02B1;
    }
    .filepond--drop-label{
        border: 1px solid #555876 !important;
        border-radius: 10px;
        background-color: #FFFCF8 !important;
    }
    .filepond--root .filepond--credits{
        display:none;
    }
    .abc{
        display:none;
    }
    .contact-input-style1 {
        margin-bottom: 1.5rem;
        border-radius: 0px;
        color:#535674;
        border: none;
        outline: none;
        background-color: transparent;
        border-bottom: 1px solid #535674;
    }
    .contact-input-style1:hover {
        border-bottom: 1px solid #535674; 
        color: #535674;
    }
    .cc-cen{
        display:flex;
        justify-content:center;
    }
    .css-jpbqk9 {
        font-size:xxx-large;
    }
    .join-p{
        font: normal normal bold 60px/116px Jost;
        color:white;
        display: flex;
        align-items: center;
    }
    .con-career{
        font: normal normal normal 20px/35px Jost;
        color:white;
    }
    .mar-con{
        margin:80px;
        margin-left:60px;
    }
    .btn-des{
        padding: 0.6rem 2rem;
        color:white;
        background: #3F02B1 0% 0% no-repeat padding-box;
        border:#3F02B1;
        font-weight: 600;
        
    }
    .mar-btn{
        margin-left: 60px;
        margin-top: -80px;
    }
    .frm-back{
        background: #FFFBF5 0% 0% no-repeat padding-box;
    }
    .frm-head{
        display: flex;
        justify-content: center;
    }
    .frm-hd1{
       font: normal normal bold 48px/69px Jost;
       margin-top:35px;
       color:#3F02B1;
    }
    .frm-hd2{
        font: normal normal normal 18px/35px Jost;
        color:#535674;
     }
    .frm-con-cnt{
        display:flex;
        justify-content:center;
    }
    .mar-bot{
        margin-bottom:100px;
    }
    .flat-l{
        margin-bottom:40px;
        color:  #535674;
        font: normal normal normal 20px/35px Jost;
        letter-spacing: -0.17px;
        opacity:1;
    }
    .frm-flat{
      background:#FFFBF5;
      border:none;
      border-radius:0px;
      border-bottom:1px solid #535674;
      color: #535674;
    }
    .frm-flat:focus {
        border:none;
        background:#FFFBF5;
        box-shadow: none;
        border-radius:0px;
        border-bottom:1px solid #535674;
        }
        .flat-l1{
            color:  #535674;
            font: normal normal normal 16px/30px Jost;
            letter-spacing: -0.17px;
            opacity:1;
        }
        .msg-txt{
            height:75px;
            border: 1px solid #555876;
            background-color:#FFFBF5;
            color:  #535674;
            margin-bottom:24px;
        }
        .msg-txt:focus{
            box-shadow:none;
            border: 1px solid #555876;
            background-color:#FFFBF5;
            color:  #535674;
        }
        .file-txt{
            height:100px;
            border: 1px solid #555876;
            border-radius:5px;
            background-color:#FFFBF5;
            color:  #535674
        }
       .file-bott{
        align-items: center;
        display: flex;
        height: 40px;
        justify-content: center;
}
.css-821wyw-MuiSvgIcon-root{
    width: 25em;
    height: 1em;
    font-size: xxx-large;
}
.privacy{
    font: normal normal normal 16px/35px Jost;
    letter-spacing: -0.17px;
    color: #535674;
}
.policy{
    font: normal normal normal 16px/35px Jost;
    letter-spacing: -0.17px;
    color:#FD8B00;
}
.btn-des1{
    padding: 0.5rem 3.5rem;
    color:white;
    background: #FD8B00 0% 0% no-repeat padding-box;
    border:#FD8B00;
}
@media (min-width:1024px) and (max-width:1227px) { 
    .con-career {
        font-size: 15px;
}  
.css-821wyw-MuiSvgIcon-root {
    width:17em;
}   
}
@media screen and (max-width: 320px) {
    .css-821wyw-MuiSvgIcon-root {
        width:16rem;
    }
    .frm-hd2{
        font-size:18px;
    }
    .frm-hd1 {
        font-size:35px;
        text-align: center;
    }
    .mar-bot {
        padding:0px 15px;
    }
    .career-page {
        height: auto;
    }
    .Carrer-head {
        background-size: auto;
        background-position: left;
    }
    .mar-con {
          margin: auto;
    }
    .join-p {
        font-size:47px;
    }
    .mar-btn{
        margin: 50px 26px 47px 50px;
    }
    .con-career{
        font-size:17px;
    }
    .btn-des1{
        margin: 10px 5px 30px 52px;
    }
    .mar-bot {
        margin-bottom: 50px;
    }
    .btn-des {
        width: 159px;
    }
}
@media (min-width:321px) and (max-width:480px) {
    .css-821wyw-MuiSvgIcon-root {
        width:22rem;
    }
    .frm-hd2{
        font-size:18px;
        padding: 0rem 1rem;
    }
    .frm-hd1 {
        font-size:43px;
        text-align: center;
    }
    .career-page {
        height: auto;
    }
    .Carrer-head {
        background-size: auto;
        background-position: left;
    }
    .mar-con {
        margin: 18px;
  }
  .join-p {
      font-size:53px;
  }
  .mar-btn{
      margin: 50px 26px 47px 20px;
  }
  .con-career{
      font-size:18px;
  }
  .btn-des1{
      margin: 10px 5px 30px 0px;
  }
  .mar-bot {
      margin-bottom: 50px;
      padding:0px 0rem;
  }
   .btn-des {
    width: 180px;
}
 }
 @media (min-width: 481px) and (max-width: 768px) {
    .Carrer-head{
        height:525px;
    }
    .css-821wyw-MuiSvgIcon-root {
        width:10em;
    }

    .frm-hd2{
        font-size:17px;
        padding: 0rem 0.5rem;
    }
    .frm-hd1 {
        font-size:45px;
        text-align: center;
    }
    .join-p {
        font-size:40px;
        margin-top:-80px;
    }
    .mar-con {
        margin: 110px 18px 18px 72px;
  }
  .con-career {
    font-size: 11px;
    line-height: 17px;
}
.mar-btn {
  margin:0px;
  margin-top:-60px;
  margin-left:72px;
}
.mar-bot{
    padding:0rem 3rem;
}

}
/* @media (min-width:601px) and (max-width:770px) {  
    .Carrer-head{
        height:525px;
    }
    .css-821wyw-MuiSvgIcon-root {
        width:14em;
    } 
    .career-page {
        height: 500px;
    }
    .join-p {
        font-size:40px;
    }
    .mar-con {
        margin: 110px 18px 18px 50px;
  }
  .con-career {
    font-size: 13px;
    line-height: 17px;
}
.mar-bot{
    padding:0px 30px;
}
.mar-btn {
    margin:0px;
    margin-top:-60px;
    margin-left:50px;
  }
} */
  @media (min-width: 769px) and (max-width: 1023px) {  
    .css-821wyw-MuiSvgIcon-root {
        width:16em;
    } 
    .career-page {
        height: 600px;
    }
    .join-p {
        font-size:40px;
    }
    .mar-con {
        margin: 110px 18px 18px 10px;
  }
  .con-career {
    font-size: 13px;
    line-height: 17px;
}
.mar-btn {
    margin:0px;
    margin-top:-60px;
  }
}
}`;

export default Careerwrap;