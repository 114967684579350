import React, { useState } from "react";
import "../TymePluspage/index.css"
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import tymePlusImg1 from "../../assets/tymplus1.png"
import tymePlusImg2 from "../../assets/tymplus2.png";
import tymePlusImg3 from "../../assets/tymplus3.png";
import tymePlusImg4 from "../../assets/tymplus4.png";
import tymePlusImg5 from "../../assets/tymplus5.png";
import Projects from "../Projects";
import Command from "../../Component/Comment";
import Footer from "../../Component/Footer";
import Navbar from "../../Component/Navbar/Navbar";

function Tymepage(
  // { portfolioRef, portfolio__activeRef, portfolio__page },
  // props
) {
  const [Trekr, setTrekr] = useState([
    tymePlusImg1,
    tymePlusImg2,
    tymePlusImg4,
    tymePlusImg5,
  ]);

  const [image, isImage] = useState(tymePlusImg3);

  const onRight = () => {
    let temp = Trekr.shift();
    Trekr.push(image);
    isImage(temp);
  };

  const onLeft = () => {
    let temp = Trekr.pop();
    Trekr.unshift(image);
    isImage(temp);
  };

  function clickToChange(it, idx) {
    let trkr = Trekr;
    delete trkr[idx]
    trkr[idx] = image
    setTrekr(trkr)
    isImage(it)
  }
  return (
    <>
      <div>
        <Navbar isShow={true} />
        <div className="Portfoliopage-Image" id="portfolio">
          <div className="Portfolio-logo">{/* <h1>Portfolio</h1> */}</div>
        </div>
        <div className="tymePage-container">
          <div className="tymePage-header">
            <h1>Human Resource Management Information System - HRMIS</h1>
            <p style={{ textAlign: "justify" }}>
              The Human Resource Management Information System is an in-depth
              interdepartmental HR and administrative tool designed to help
              businesses keep track of staff entitlements, hours worked, and
              other customisable metrics. Features such as location validation
              and custom overview reporting have made the foresyt ERP platform a
              success for businesses with a large number of employees, or staff
              spread across multiple locations.
            </p>
          </div>
          <div className="portal-section">
            <div className="tymePlus-carousel">
              <div className="tymplus-carousel-motion">
                <img src={image} alt="Images" />
              </div>
              <div className="tymplus-carousel-motion1">
                {Trekr.map((item, index) => {
                  return (
                    <img
                      onClick={() => clickToChange(item, index)}
                      style={{ cursor: "pointer" }}
                      src={item}
                      key={index}
                      alt="tymeplusImages"
                    />
                  );
                })}
              </div>
            </div>
            <div className="portal-button">
              <button onClick={onRight}>
                <BsChevronLeft />
              </button>
              <button onClick={onLeft}>
                <BsChevronRight />
              </button>
            </div>
          </div>
          <div className="typlus-all-contents">
            <div className="tymePage-dashbord">
              <div className="dashboard-header">
                <h1>Staff Portal</h1>
              </div>
              <div className="dashboard-contant">
                <p style={{ textAlign: "justify" }}>
                  The staff portal allows users to log work start and finish
                  times, with inbuilt location validation, allowing managers to
                  ensure staff are at their assigned worksites at the time of
                  log in. The system also features facial recognition technology
                  for added security.
                </p>
              </div>
            </div>
            <div className="tymplus-hod-container">
              <div className="hod-contants">
                <h1>Head-of-department Dashboard</h1>
                <p style={{ textAlign: "justify" }}>
                  Department managers can easily track allotted staff, with the
                  head-of-department dashboard providing quick-access overviews
                  of staff attendance, accrued and consumed leave balances, and
                  more.
                </p>
              </div>
            </div>{" "}
            <div className="portal-header">
              <h1>Administration Portal</h1>
              <p style={{ textAlign: "justify" }}>
                The administration portal allows senior management staff to
                overview a range of business metrics taken from data points
                captured for staff, such as staff-to-cost ratios, overtime
                trends, and more to help with strategic decision making.
              </p>
            </div>
          </div>
        </div>
        <Projects />
        <Command />
        <Footer />
      </div>
    </>
  );
}
export default Tymepage