import React, { useEffect } from "react";
import "./App.css";
import Landing from "./Component/Home/index";
import { useLocation } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import About from "./Pages/About/About";
import Contact from "./Component/Contactus";
import Tymepage from "./Pages/TymePluspage";
import Trekrpage from "./Pages/TrekrPage";
import Bidleadpage from "./Pages/BidleadPage";
import Career from "./Pages/Career/Career";
import Price from "./Component/Price";
import Workflowpage from "./Pages/workflow";

function ScrollToTop({ children }) {
  const { pathname } = useLocation();
  useEffect(() => {
    const canControlScrollRestoration = "scrollRestoration" in window.history;
    if (canControlScrollRestoration) {
      window.history.scrollRestoration = "manual";
    }
    window.scrollTo(0, 0);
  }, [pathname]);
  return children;
}

function App() {
  return (
    <>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Landing />} exact />
          <Route path="about" element={<About />} exact />
          <Route path="tymplus" element={<Tymepage />} exact />
          <Route path="trekr" element={<Trekrpage />} exact />
          <Route path="bidlead" element={<Bidleadpage />} exact />
          <Route
            path="Workflow_and_FormBuilder"
            element={<Workflowpage />}
            exact
          />
          <Route path="career" element={<Career />} exact />
          <Route path="pricing" element={<Price />} exact />
          <Route path="contact_us" element={<Contact />} exact />
        </Routes>
      </ScrollToTop>
    </>
  );
}

export default App;
