import React from "react";

import "../CommendSection/index.css"

import { GoMailRead } from "react-icons/go";

function CommendSection() {
  return (
    <>
      <div className="touch-container">
        <div className="touch-header">
          <h1>Get in touch</h1>
          <p>Alternatively, you can reach out to us using the methods below:</p>
        </div>
        <div className="send-mail-field">
          <GoMailRead
            className="mail-icon"
            style={{ fontSize: "80px", color: "#FD8B00", border: "1px" }}
          />
          <div className="mail-content">
            <p>Write to us at</p>
            <h1>info@foresyt.co</h1>
          </div>
        </div>
      </div>
    </>
  );
}
export default CommendSection