import React from "react";

import "./index.css"

import Lottie from "lottie-react";

import lottieAnimation from "../../lf30_editor_dp8z5wrg.json";

import avator from "../../assets/input-avator.png";

import { BsFillArrowRightCircleFill } from "react-icons/bs";

import { Link } from "react-router-dom";

function Command({ contactClick }) {
  return (
    <>
      <div className="command-section">
        <div className="lottiefile">
          <Link to="/contact_us">
            <Lottie animationData={lottieAnimation} className="lottie" />
          </Link>
        </div>
        <div className="input-area">
          <div className="input-content">
            <div className="input-top">
              <h1 style={{ color: "#EF8F35" }}>Lets take the next step,</h1>
            </div>
            <div className="input-avator">
              <img src={avator} />
            </div>
          </div>
          <div className="input-box">
            <p>Write to us at</p>
            <h2>info@foresyt.co</h2>
            <Link to="/contact_us">
              <BsFillArrowRightCircleFill className="arrow-key" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Command;