import React, { useRef } from "react";
import { NavLink } from "react-router-dom";
function Custom({ contact__Click, props }) {
  return (
    <>
      <div className="product-solution">
        <h2>foresyt product solutions</h2>
        <p>
          All products shown on the foresyt website are available on either a
          pay-as-you-go subscription or for a flat fee.
        </p>
      </div>
      <div className="price-subscription">
        <div className="subscription">
          <div className="subscription-header">
            <h2>Subscriptions</h2>
          </div>
          <div className="subscrition-content">
            <p>
              Subscriptions to any foresyt product are offered on a recurring
              monthly or annual basis for an agreed upon fee. The foresyt team
              will provide ongoing support and limited platform customisation.
              In-depth product customisation is available for an additional
              charge.{" "}
              <NavLink
                style={{ color: "#FD8B00", textDecoration: "none" }}
                onClick={contact__Click}
              >
                Contact us
              </NavLink>{" "}
              to discuss a subscription plan that suits your needs.
            </p>
          </div>
        </div>
        <div className="subscription-services">
          <div className="subscription-header">
            <h2>Flat-fee Services</h2>
          </div>
          <div className="subscrition-content">
            <p>
              You can purchase any foresyt product for a once-off fee.
              Customisation, product modification, and ongoing product
              support can be for an additional fee.{" "}
              <NavLink
                style={{ color: "#FD8B00", textDecoration: "none" }}
                onClick={contact__Click}
              >
                Contact us
              </NavLink>{" "}
              to find out more.
            </p>
          </div>
        </div>
      </div>
      <div className="custom-container">
        <h1>Custom software solutions</h1>
        <p>
          Looking for a custom software solution? The foresyt team can work with
          you from research through to design and development to create a
          product that is tailored to your needs. Fees will vary based on the
          scale and scope of the project. To get started, contact us for a
          quote.
        </p>
      </div>
    </>
  );
}

export default Custom;