import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion'
import { useSwipeable } from "react-swipeable";
import { NavLink } from "react-router-dom";
import img7 from "../../assets/trek1.png";
import img8 from "../../assets/trekr3.png";
import img9 from '../../assets/trekr2.png'

import { GrFormNext, GrFormPrevious } from "react-icons/gr";

import { BsChevronRight, BsChevronLeft } from "react-icons/bs";

function TrekrSection() {
  const [process3, setProcess3] = useState(0)
  const [Trekr, setTrekr] = useState([img7, img8, img9])
  const [isPhoneSize, setIsPhoneSize] = useState(false);
  const [isbutton, setButton] = useState(false);

  useEffect(() => {
    handleSlide1();
  }, [Trekr]);

  function handleSlide1() {
    setInterval(() => {
      setProcess3((prev) => (prev + 1) % Trekr.length);
    }, 6000);
  }

  useEffect(() => {
    window.addEventListener("resize", (e) => {
      if (window.innerWidth < 780) {
        setIsPhoneSize(true)
      } else {
        setIsPhoneSize(false);
      }
    })
  }, [])

  useEffect(() => {
    if (window.innerWidth < 780) {
      setIsPhoneSize(true);
    } else {
      setIsPhoneSize(false);
    }
  }, [])

  useEffect(() => {
    window.addEventListener("resize", (e) => {
      if (window.innerWidth < 780) {
        setButton(true);
      } else {
        setButton(false);
      }
    });
  }, []);

  useEffect(() => {
    if (window.innerWidth < 780) {
      setButton(true);
    } else {
      setButton(false);
    }
  }, []);

  const handleSwipe = useSwipeable({
    onSwiped: ({ dir }) => {
      console.log("dir", dir)
      if (dir === "Right") {
        if (process3 > 0) {
          setProcess3(process3 - 1);
        }
      }
      if (dir === "Left") {
        if (process3 < 2) {
          setProcess3(process3 + 1);
        }
      }
    },
  });

  const onRight = () => {
    if (process3 < Trekr.length - 1) {
      setProcess3(process3 + 1);
    }
  };
  const onLeft = () => {
    if (process3 > 0) {
      setProcess3(process3 - 1);
    }
  };

  return (
    <>
      <section className={`${isPhoneSize ? "mobile-view" : "no"}`}>
        <div style={{ height: "100%", width: "100%" }}>
          <div {...handleSwipe} className="carousel-container">
            <div className="carousel">
              {Trekr.map((it, index) => (
                <motion.div
                  key={index}
                  onClick={() => {
                    setProcess3(index);
                  }}
                  className={`framer ${isPhoneSize ? "mobile-view" : ""}`}
                  initial={{ scale: 0, rotation: -180 }}
                  animate={{
                    rotate: 0,
                    scale: index === process3 ? 1 : 0.8,
                    left: isPhoneSize
                      ? `${(index - process3) * 100}%`
                      : `${(index - process3) * 65 + 15}%`,
                  }}
                  transition={{
                    type: "spring",
                    stiffness: 260,
                    damping: 20,
                  }}
                >
                  <div className="framerImg">
                    <img src={it} alt="Images" />
                  </div>
                </motion.div>
              ))}
              {!isbutton && (
                <div
                  className="indicator__wappper"
                  style={{ position: "relative", cursor: "pointer" }}
                >
                  {/* <button className="slide__btn1" onClick={onLeft}>
                    <GrFormPrevious />
                  </button>
                  <button
                    className="slide__btn"
                    onClick={onRight}
                  >
                    <GrFormNext style={{ color: "red" }} />
                  </button> */}
                  <div className="button__indicator">
                    {Array(3)
                      .fill(0)
                      .map((item, index) => {
                        return (
                          <div
                            onClick={(e) => setProcess3(index)}
                            className={`dot ${index === process3 ? "active" : ""
                              }`}
                          ></div>
                        );
                      })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={`${isbutton ? "tymPlus-button" : "tymPlus-button1"}`}>
          <button
            onClick={onRight}
            className={`${isbutton ? "tymPlus-button button" : "no"}`}
          >
            <BsChevronLeft />
          </button>
          <button
            onClick={onLeft}
            className={`${isbutton ? "tymPlus-button button" : "no"}`}
          >
            <BsChevronRight />
          </button>
        </div>
        <div className="portfolio-titles">
          <h1>Project Management Software</h1>
          <p>
            The foresyt Project Management platform is a versatile task, project,
            and workflow management tool, designed with customisability and
            adaptability front-of-mind. It allows you to create tailored
            processes and workflows that match your business needs. Manage
            multiple projects and departments across your entire business.
          </p>
          <NavLink to="/trekr" activeClassName="active">
            <button className="port-btn">Find Out More</button>
          </NavLink>
        </div>
      </section>
    </>
  );
}

export default TrekrSection;