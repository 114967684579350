import React, { useRef } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Careerwrap from "./style";
import CareerForm from "./Forms";
import Navbar from "../../Component/Navbar/Navbar";
import Footer from "../../Component/Footer";

function Career({ carrearRef, carrear__activeRef }) {
  const contactusRef = useRef(null);

  function contactusClick() {
    contactusRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }
  return (
    <>
      <div>
        <Navbar isShow={true} />
        <Careerwrap id="carrears" ref={carrearRef}>
          <Container fluid className="Carrer-head">
            <Row className="career-page">
              <Col lg={6} xs={12} md={6} sm={6} className="mar-con">
                <span className="join-p">Join the team</span>
                <span className="con-career">
                  Become a part of a driven, future-looking team at foresyt. We
                  provide a top-quality work environment with significant
                  opportunities for growth and development. Gain hands-on
                  experience working with clients across the world.
                </span>
              </Col>
              <Col lg={7} xs={12} md={12} sm={12} className="mar-btn">
                <button className="btn-des" onClick={contactusClick}>
                  Contact Us Now
                </button>
              </Col>
            </Row>
          </Container>
          <CareerForm ref={contactusRef} />
        </Careerwrap>
        <Footer />
      </div>
    </>
  );
}
export default Career;