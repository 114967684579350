import React, { forwardRef, useRef, useState } from "react";
import { Row, Col, Container, Form, FloatingLabel, Spinner } from "react-bootstrap";
import Careerwrap from "./style";
import { Modal } from "react-bootstrap";
import { GoFile } from "react-icons/go";

import { useFormik, Field } from "formik";

import { carrearSchema } from "../../Component/schemas/carrearSchema";

import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import DescriptionIcon from "@mui/icons-material/Description";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import ReCAPTCHA from "react-google-recaptcha";


registerPlugin(
  FilePondPluginFileValidateType,
  FilePondPluginImageExifOrientation
);

const normalizeNumber = (value) => {
  // console.log('normalizeNumber  value', value.length)
  const firstIndex = value.length === 1
  const lastIndex = value.length - 1

  if (value.length <= 14) {   //* Eg. +913431533234 some country have 2 digit country code some country have 3 digit.
    if (value == "+" && firstIndex) {
      return value
    } else if (value[lastIndex] == "+") {
      return value.substring(0, lastIndex)
    }
    else {
      return value.replace(/[^0-9+]/g, '')
    }
  }
  return value.substring(0, 14)
}

function CareerForm(props, ref) {

  const career__Reset = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [recaptcha, setRecaptcha] = useState(false);
  const [loading, setLoading] = useState(false)


  const onSubmit = (values, actions) => {

    recaptcha && setLoading(true)
    recaptcha && fetch(
      "https://d4n4e2rgq8.execute-api.ap-south-1.amazonaws.com/test/contactus",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        actions.setSubmitting(false);
        setLoading(false)
        setShowModal(true);
        career__Reset.current.reset();
      })
      .catch((error) => {
        console.error(error);
        actions.setSubmitting(false);
        setLoading(false)
      });
  }


  const { values, errors, touched, handleChange, setFieldValue, handleSubmit } =
    useFormik({
      initialValues: {
        name: "",
        email: "",
        number: "",
        message: "",
        file: null,
      },
      validationSchema: carrearSchema,
      onSubmit,
    });

  const reCaptchaOnChange = (value) => {
    setRecaptcha(true)
  }

  /***************************File uploading function*******************************/

  return (
    <>
      <Careerwrap ref={ref}>
        <Container fluid className="frm-back">
          <Row>
            <Col lg={12} className="frm-head">
              <p className="frm-hd1">Connect with us!</p>
            </Col>
          </Row>
          <Row className="frm-head">
            <Col sm={10} className="frm-head">
              <p className="frm-hd2">
                Interested in joining the team? Send us a message with your
                resume and contact details using the form below
              </p>
            </Col>
          </Row>
          <div className="subscrition-contact" id="price__contactus">
            <form
              ref={career__Reset}
              name="careear_form"
              onSubmit={handleSubmit}
            >
              <div className="contact__input">
                <FloatingLabel
                  controlId="floatingName"
                  label="Name"
                  className="mb-3 float__tag"
                  style={{ color: "#535674" }}
                >
                  <Form.Control
                    className="pricing-contact-input-style shadow-none"
                    onChange={handleChange}
                    type="name"
                    // id="name"
                    name="name"
                    placeholder="Enter the name"
                  />
                </FloatingLabel>
                {errors.name && touched.name && (
                  <p className="text-danger">{errors.name}</p>
                )}
                <FloatingLabel
                  className="mb-3 float__tag"
                  controlId="floatingPassword"
                  label="Email address"
                  style={{ color: "#535674" }}
                >
                  <Form.Control
                    className="pricing-contact-input-style shadow-none"
                    onChange={handleChange}
                    type="email"
                    // id="email"
                    name="email"
                    placeholder="Enter the email"
                  />
                </FloatingLabel>
                {errors.email && touched.email && (
                  <p className="text-danger">{errors.email}</p>
                )}
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contact number (including country code)"
                  className="mb-3 float__tag"
                  style={{ color: "#535674" }}
                >
                  <Form.Control
                    className="pricing-contact-input-style shadow-none"
                    type="text"
                    onChange={(e) => {
                      const { value } = e.target
                      setFieldValue("number", normalizeNumber(value), false)
                      e.target.value = normalizeNumber(value)
                    }}
                    name="number"
                    // id="number"
                    placeholder="Enter the number"
                  />
                </FloatingLabel>
                {errors.number && touched.number && (
                  <p className="text-danger">{errors.number}</p>
                )}
                <label style={{ color: "#535674", paddingBottom: "8px" }}>
                  Type your Message:
                </label>
                <br></br>
                <textarea
                  type="text-area"
                  className="pricing-contact-text-area"
                  // value={message}
                  onChange={handleChange}
                  id="message"
                ></textarea>
                {errors.message && touched.message && (
                  <p className="text-danger">{errors.message}</p>
                )}
              </div>
              <label
                style={{
                  paddingTop: "1rem",
                  paddingBottom: "8px",
                  color: "#535674",
                }}
              >
                Attach Document:
              </label>
              <div>
                <FilePond
                  name="file"
                  allowMultiple={false}
                  acceptedFileTypes={["application/pdf"]}
                  onupdatefiles={(fileItems) => {
                    setFieldValue("file", fileItems[0].file);
                  }}
                  labelIdle={`
                <div>Attach your resume here</div>
                `}
                />
                {errors.file && touched.file ? (
                  <p className="text-danger">*Documents is required</p>
                ) : null}
              </div>

              <div className="mt-2">
                <ReCAPTCHA
                  sitekey="6Ld_6Z0kAAAAAIzctrEhT2cPhmtpVE2GRqiQFH5c" // Create site Key from https://www.google.com/recaptcha/admin/create
                  onChange={reCaptchaOnChange}
                />
              </div>

              {!recaptcha && touched.email && (
                <p className="text-danger">{!recaptcha && "*reCAPTCHA is required"}</p>
              )}
              <div className="price-contact">
                <button type="submit">{loading ? <Spinner animation="border" size="sm" /> : "Submit"}</button>
              </div>
            </form>
          </div>
        </Container>
      </Careerwrap>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        // style={modalStyle}
        style={{ backgroundColor: "none" }}
        centered
      >
        <Modal.Header closeButton closeVariant="white" style={{ border: "none", background: "linear-gradient(90deg, rgba(253,139,0,1) 7%, rgba(26,2,177,1) 100%, rgba(253,139,0,1) 100%)" }}>
          <Modal.Title style={{ color: "#1A02B1", fontSize: "32px" }}>
            foresyt.co
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ color: "#FD8B00", fontSize: "22px" }}>
            {`Hi ${values.name} `} <br></br>Thank you for Contacting us...
          </p>
          {/* <p style={{ color: "#FD8B00" }}>File is uploaded successfully</p> */}
        </Modal.Body>
      </Modal>
    </>
  );
}
export default forwardRef(CareerForm);