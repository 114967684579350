import React from "react";
import { Container } from "react-bootstrap";
import Navbar from "../../Component/Navbar/Navbar";


function Landingab () {
    return (
      <>
        <Navbar isShow={true} />
        <Container fluid className="con-page">
          <h1 className="about-con">About us</h1>
        </Container>
      </>
    );
  }
  export default Landingab;