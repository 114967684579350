import React, { useState } from "react";
import "./Navbar.css";
import { NavLink } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { GrClose } from "react-icons/gr";
import logo from "../../assets/foresyt.svg";

function Navbar(props) {
  const [mobileView, setMobileView] = useState(false);
  const [change, setChange] = useState(false)
  const changeBackground = (e) => {
    if (window.scrollY > 0) {
      setChange(true);
    } else {
      setChange(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <>
      <div className={change ? "custom__navbar nav__scroll" : "custom__navbar"}>
        <div className="navigator">
          <NavLink exact className="nav-link">
            <img className="foresyt-logo" src={logo} alt="foresyt" />
          </NavLink>
          <div
            className={`nav_items ${mobileView ? "nav_items active" : ""} 
              ${change ? "nav_items hamburger" : "nav_items"}`}
          >
            <NavLink
              to="/"
              exact
              activeClassName="active"
              className="nav-link"
            >
              Home
            </NavLink>
            <NavLink
              to="/about"
              activeClassName="active"
              className="nav-link"
            >
              About Us
            </NavLink>
            <NavLink
              to="/tymplus"
              activeClassName="active"
              className="nav-link"
            >
              Portfolio
            </NavLink>
            <NavLink
              to="/career"
              activeClassName="active"
              className="nav-link"
            >
              Careers
            </NavLink>
            <NavLink
              to="/pricing"
              activeClassName="active"
              className="nav-link"
            >
              Pricing
            </NavLink>
            {mobileView === true ? (
              <NavLink
                to="/contact_us"
                activeClassName="activelink"
                className="nav-link"
              >
                Contact us
              </NavLink>
            ) : (
              ""
            )}
          </div>
        </div>

        {props.isShow == true && (
          <div className="mobile-menu-icon">
            {mobileView == false ? (
              <GiHamburgerMenu
                className={change ? "hamIcon hamburger__Icon" : "hamIcon"}
                style={{ fontSize: "22px" }}
                onClick={(e) => setMobileView(!mobileView)}
              />
            ) : (
              <GrClose
                className="closeIcon"
                style={{ color: "white", fontSize: "22px" }}
                onClick={(e) => setMobileView(!mobileView)}
              />
            )}
          </div>
        )}

        {props.isShow && (
          <NavLink
            to="/contact_us"
            activeClassName="active"
            className={change ? "contact__us con__active" : "contact__us"}
            style={{ textDecoration: "none" }}
          >
            Contact us
          </NavLink>
        )}
      </div>
    </>
  );
}

export default Navbar;
