import React, { useState } from "react";
import "../TymePluspage/index.css";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import workflowImg1 from "../../assets/workflowImg1.png";
// import workflowImg2 from "../../assets/workflowImg2.png";
import workflowImg3 from "../../assets/workflowImg3.png";
import workflowImg4 from "../../assets/workflowImg4.png";

import Projects from "../Projects";
import Command from "../../Component/Comment";
import Footer from "../../Component/Footer";
import Navbar from "../../Component/Navbar/Navbar";

function Workflowpage() {
  // { portfolioRef, portfolio__activeRef, portfolio__page },
  // props
  const [Trekr, setTrekr] = useState([
    workflowImg1,
    // workflowImg2,
    workflowImg3,
    workflowImg4,
  ]);

  const [image, isImage] = useState(workflowImg1);

  const onRight = () => {
    let temp = Trekr.shift();
    Trekr.push(image);
    isImage(temp);
  };

  const onLeft = () => {
    let temp = Trekr.pop();
    Trekr.unshift(image);
    isImage(temp);
  };

  function clickToChange(it, idx) {
    let trkr = Trekr;
    delete trkr[idx];
    trkr[idx] = image;
    setTrekr(trkr);
    isImage(it);
  }
  return (
    <>
      <div>
        <Navbar isShow={true} />
        <div className="Portfoliopage-Image" id="portfolio">
          <div className="Portfolio-logo">{/* <h1>Portfolio</h1> */}</div>
        </div>
        <div className="tymePage-container">
          <div className="tymePage-header">
            <h1>Workflow and form builder</h1>
            <p style={{ textAlign: "justify" }}>
              The foresyt workflow and form builder is an automation powerhouse
              for lead generation, data & document collection, and workflow
              management, and more. The platform comprises a range of tools and
              features that make it the perfect tool for lead generation, client
              onboarding and retention, data collection, digital application
              processing, marketing, and endless other applications across a
              range of industries.
            </p>
          </div>
          <div className="portal-section">
            <div className="tymePlus-carousel">
              <div className="tymplus-carousel-motion">
                <img src={image} alt="Images" />
              </div>
              <div className="tymplus-carousel-motion1">
                {Trekr.map((item, index) => {
                  return (
                    <img
                      onClick={() => clickToChange(item, index)}
                      style={{ cursor: "pointer" }}
                      src={item}
                      key={index}
                      alt="tymeplusImages"
                    />
                  );
                })}
              </div>
            </div>
            <div className="portal-button">
              <button onClick={onRight}>
                <BsChevronLeft />
              </button>
              <button onClick={onLeft}>
                <BsChevronRight />
              </button>
            </div>
          </div>
          <div className="typlus-all-contents">
            <div className="tymePage-dashbord">
              <div className="dashboard-header">
                <h1>Features include:</h1>
              </div>
              <div className="dashboard-contant">
                <p style={{ textAlign: "justify" }}>
                  Chatbots - Integrate chatbots seamlessly with your website to
                  encourage engagement, effortlessly generate leads, and offer
                  agent-free support to users and customers.
                </p>
              </div>
            </div>
            <div className="tymplus-hod-container">
              <div className="hod-contants">
                {/* <h1>Head-of-department Dashboard</h1> */}
                <p style={{ textAlign: "justify" }}>
                  Forms - Build custom forms for anything from collecting client
                  details to processing product applications. Forms can be
                  embedded into existing websites, or sent to clients through a
                  shared link.
                </p>
              </div>
            </div>{" "}
            <div className="portal-header">
              {/* <h1>Administration Portal</h1> */}
              <p style={{ textAlign: "justify" }}>
                Documents - Streamline your document collection process with
                automated document checklists, reminders, and workflows Workflow
                Automation - Optimise your business with automated follow ups,
                data collection, client onboarding, and more.
              </p>
            </div>
          </div>
        </div>
        <Projects />
        <Command />
        <Footer />
      </div>
    </>
  );
}
export default Workflowpage;
