import React, { useRef, useState } from "react";
import "./index.css";
import Custom from "./Custom";
import Navbar from "../Navbar/Navbar";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Footer from "../Footer";
import { Modal, Spinner } from "react-bootstrap";
import { useFormik } from "formik";
import { basicSchema } from "../schemas";
import ReCAPTCHA from "react-google-recaptcha";

const normalizeNumber = (value) => {
  // console.log('normalizeNumber  value', value.length)
  const firstIndex = value.length === 1
  const lastIndex = value.length - 1

  if (value.length <= 14) {   //* Eg. +913431533234 some country have 2 digit country code some country have 3 digit.
    if (value == "+" && firstIndex) {
      return value
    } else if (value[lastIndex] == "+") {
      return value.substring(0, lastIndex)
    }
    else {
      return value.replace(/[^0-9+]/g, '')
    }
  }
  return value.substring(0, 14)
}

function Price() {

  const [showModal, setShowModal] = useState(false);
  const [recaptcha, setRecaptcha] = useState(false)
  const [loading, setLoading] = useState(false)

  const onSubmit = (values, actions) => {
    recaptcha && setLoading(true)
    recaptcha && fetch(
      "https://d4n4e2rgq8.execute-api.ap-south-1.amazonaws.com/test/contactus",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        actions.setSubmitting(false);
        setShowModal(true);
        price__reset.current.reset();
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        console.error(error);
        actions.setSubmitting(false);
      });
  };

  const price__reset = useRef(null);

  const { values, errors, touched, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      name: "",
      email: "",
      number: "",
      message: "",
    },
    validationSchema: basicSchema,
    onSubmit,
  });

  function contact__Click() {
    const element = document.getElementById("price__contactus");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  const reCaptchaOnChange = (value) => {
    setRecaptcha(true)
  }

  return (
    <>
      <div>
        <Navbar isShow={true} />
        <div className="price-container" id="priceing">
          <div className="pricing-header">
            <h1>Pricing</h1>
            <p>
              Interested in taking the next step? We've got solutions for every
              business and every need.
            </p>
          </div>
        </div>
        <Custom contact__Click={contact__Click} />
        <div className="subscrition-contact" id="price__contactus">
          <div className="subscrition-contact-heading">
            <h1
              style={{ color: "#535674", fontSize: "38px", fontWeight: "bold" }}
            >
              Contact us
            </h1>
          </div>
          <form ref={price__reset} name="con__form" onSubmit={handleSubmit}>
            <div className="contact__input">
              <FloatingLabel
                controlId="floatingName"
                label="Name"
                className="mb-3 float__tag"
                style={{ color: "black" }}
              >
                <Form.Control
                  className="pricing-contact-input-style shadow-none"
                  onChange={handleChange}
                  type="name"
                  // id="name"
                  name="name"
                  placeholder="Enter the name"
                />
              </FloatingLabel>
              {errors.name && touched.name && (
                <p className="text-danger">{errors.name}</p>
              )}
              <FloatingLabel
                className="mb-3 float__tag"
                controlId="floatingPassword"
                label="Email address"
                style={{ color: "black" }}
              >
                <Form.Control
                  className="pricing-contact-input-style shadow-none"
                  onChange={handleChange}
                  type="email"
                  // id="email"
                  name="email"
                  placeholder="Enter the email"
                />
              </FloatingLabel>
              {errors.email && touched.email && (
                <p className="text-danger">{errors.email}</p>
              )}
              <FloatingLabel
                controlId="floatingInput"
                label="Contact number (including country code)"
                className="mb-3 float__tag"
                style={{ color: "black" }}
              >
                <Form.Control
                  className="pricing-contact-input-style shadow-none"
                  onChange={(e) => {
                    const { value } = e.target
                    setFieldValue("number", normalizeNumber(value), false)
                    e.target.value = normalizeNumber(value)
                  }}
                  type="text"
                  // id="number"
                  name="number"
                  placeholder="Enter the number"
                />
              </FloatingLabel>
              {errors.number && touched.number && (
                <p className="text-danger">{errors.number}</p>
              )}
              <label style={{ color: "black", paddingBottom: "8px" }}>
                Type your Message:
              </label>
              <br></br>
              <textarea
                type="text-area"
                className="pricing-contact-text-area"
                onChange={handleChange}
                id="message"
              ></textarea>
            </div>
            {errors.message && touched.message && (
              <p className="text-danger">{errors.message}</p>
            )}

            <div className="mt-2">
              <ReCAPTCHA
                sitekey="6Ld_6Z0kAAAAAIzctrEhT2cPhmtpVE2GRqiQFH5c" // Create site Key from https://www.google.com/recaptcha/admin/create
                onChange={reCaptchaOnChange}
              />
              {!recaptcha && touched.email && (
                <p className="text-danger">{!recaptcha && "*reCAPTCHA validation is Required"}</p>
              )}
            </div>

            <div className="price-contact">
              <button type="submit">{loading ? <Spinner animation="border" size="sm" /> : "SUBMIT"}</button>
            </div>
          </form>
        </div>
      </div>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        style={{ backgroundColor: "none" }}
        centered
      >
        <Modal.Header closeButton closeVariant="white" style={{ border: "none", background: "linear-gradient(90deg, rgba(253,139,0,1) 7%, rgba(26,2,177,1) 100%, rgba(253,139,0,1) 100%)" }}>
          <Modal.Title style={{ color: "#1A02B1", fontSize: "32px" }}>
            foresyt.co
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ color: "#FD8B00", fontSize: "22px" }}>
            {`Hi ${values.name} `} <br></br>Thank you for Contacting us...
            <br></br>
            The foresyt team will be in touch with you shortly.
          </p>
        </Modal.Body>
      </Modal>
      <Footer />
    </>
  );
}
export default Price;