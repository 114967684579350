import React, { forwardRef } from "react";
import '../Portfolio/index.css'
import { NavLink } from "react-router-dom";
import BidLead from "./Bitlead";
import TrekrSection from "./Trekr";
import TymeplusSection from "./Tymplus";

function Portfolio(props, portfolioRef) {
  return (
    <>
      <div ref={portfolioRef}>
        <div className="portfolio-container" id="/#portfolio-container">
          <h1 className="portfolio__header">Portfolio</h1>
          <TymeplusSection />
          <BidLead />
          <TrekrSection />
          <div className="see-more-button">
            <NavLink
              to="/tymplus"
              activeClassName="active"
              className="nav-link"
            >
              <button className="seemore__btn">See More</button>
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
}

export default forwardRef(Portfolio);