import React, { useState } from "react";

import "../TrekrPage/index.css";

import { BsChevronRight, BsChevronLeft } from "react-icons/bs";

import tymePlusImg1 from "../../assets/trek1.png";
import tymePlusImg2 from "../../assets/trekr2.png";
import tymePlusImg3 from "../../assets/trekr3.png";
import tymePlusImg5 from "../../assets/trekr4.png";
import tymePlusImg4 from "../../assets/trekr5.png";
import Projects from "../Projects";
import Command from "../../Component/Comment";
import Footer from "../../Component/Footer";
import Navbar from "../../Component/Navbar/Navbar";

function Trekrpage() {
  const [Trekr, setTrekr] = useState([
    tymePlusImg1,
    tymePlusImg2,
    tymePlusImg4,
    tymePlusImg5,
  ]);

  const [image, isImage] = useState(tymePlusImg3);

  const onRight = () => {
    let temp = Trekr.shift();
    Trekr.push(image);
    isImage(temp);
  };

  const onLeft = () => {
    let temp = Trekr.pop();
    Trekr.unshift(image);
    isImage(temp);
  };
  function clickToChange(it, idx) {
    let trkr = Trekr;
    delete trkr[idx];
    trkr[idx] = image;
    setTrekr(trkr);
    isImage(it);
  }
  return (
    <>
      <Navbar isShow={true} />
      <div className="Portfoliopage-Image" id="portfolio">
        <div className="Portfolio-logo">{/* <h1>Portfolio</h1> */}</div>
      </div>
      <div className="trekrPage-container">
        <div className="trekrPage-header">
          <h1>Project Management Software</h1>
          <p style={{ textAlign: "justify" }}>
            Our project management software is a versatile task, project, and
            workflow management tool, designed with customisability and
            adaptability front-of-mind. It allows you to create tailored
            processes and workflows that match your business needs. Manage
            multiple projects and departments across your entire business.
          </p>
        </div>
        <div className="portal-section">
          <div className="tymePlus-carousel">
            <div className="tymplus-carousel-motion">
              <img src={image} alt="carousel_img1" />
            </div>
            <div className="tymplus-carousel-motion1">
              {Trekr.map((item, index) => {
                return (
                  <img
                    alt="Trekr"
                    style={{ cursor: "pointer" }}
                    src={item}
                    key={index}
                    onClick={() => clickToChange(item, index)}
                  />
                );
              })}
            </div>
          </div>
          <div className="trekr-align-button">
            <button onClick={onRight}>
              <BsChevronLeft />
            </button>
            <button onClick={onLeft}>
              <BsChevronRight />
            </button>
          </div>
        </div>
        <div className="tymeplus-All-contents">
          <p style={{ textAlign: "justify" }}>
            This system also allows you to create tailored processes and
            workflows that match your business needs, allowing your team to
            manage multiple projects and departments across your entire
            business. Inbuilt reporting allows the tracking of KPI’s, project
            completion rates, and business goals. The system supports tracking
            performance across projects, teams, and individual workflows,
            allowing your team to identify core strengths and weaknesses,
            maximising productivity. The platform allows for full-team
            collaboration, with optional task approvals and role access
            customisation. Multi-view support lets users engage with the
            platform in a way that suits their style of working, and
            cross-platform functionality means that your team can access the
            platform wherever they are.
          </p>
          <p>
            Our project management software also supports integration with a
            variety of platforms, allowing you to integrate the system with the
            tools and software you already use.
          </p>
        </div>
      </div>
      <Projects />
      <Command />
      <Footer />
    </>
  );
}
export default Trekrpage;
